import service from '@/until/api'

export function _FindAnalysisData(data) {
    return service({
        url: '/report-data/dataAnalysis',
        method: 'POST',
        data
    })
}

export function _boardGeneralView() {
    return service({
        url: '/data-manage/board/overview',
        method: 'GET'
    })
}

export function _vehicleDistribution() {
    return service({
        url: '/data-manage/board/vehicle/city/distribution',
        method: 'GET'
    })
}

export function _parkVehicleRate() {
    return service({
        url: '/data-manage/board/vehicle/park/ratio',
        method: 'GET'
    })
}

export function _businessGeneralView(data) {
  return service({
    url: `/data-manage/board/vehicle/business/overview`,
    method: 'GET',
    params: data
  })
}

export function _businessDistribution(data) {
  return service({
    url: `/data-manage/board/vehicle/business/distribution`,
    method: 'GET',
    params: data
  })
}

export function _breakOverview() {
  return service({
    url: '/data-manage/board/vehicle/emergencybrake/overview',
    method: 'GET',
  })
}

export function _breakDistribution(data) {
  return service({
    url: `/data-manage/board/vehicle/emergencybrake/distribution`,
    method: 'GET',
    params: data
  })
}

export function _alarmInfo(data) {
  return service({
    url: `/data-manage/board/vehicle/alarm/detail`,
    method: 'GET',
    params: data
  })
}

export function _berakDetail(data) {
  return service({
    url: `/data-manage/brake/detail`,
    method: 'POST',
    data: data
  })
}

//下载紧急制动记录
export function _DownloadEmergencyBrakeRecord(data) {
  return service({
    url: '/protocol-nacos/carAlarm/getCarBrakePacket',
    method: 'GET',
    params: data,
    responseType: 'blob'

  })
}

export function _downloadEmergencyVideo(data) {
  return service({
    url: '/file-nacos/excelDownload/exportCarBrakeVideo',
    method: 'GET',
    params: data,
  })
}

//概览
export function _Overview(data) {
  return service({
      url: '/data-manage/analysis/overview',
      method: 'POST',
      data
  })
}

//车辆日常数据分析
export function _DailyAnalysisData(data) {
  return service({
      url: '/data-manage/analysis/daily/business',
      method: 'POST',
      data
  })
}

//车辆紧急制动行为分析
export function _EmergencyBrake(data) {
  return service({
      url: '/data-manage/analysis/vehicle/emergencybrake',
      method: 'POST',
      data
  })
}

//详细数据查询
export function _BrakeDetail(data) {
  return service({
      url: '/data-manage/brake/detail',
      method: 'POST',
      data
  })
}

//根据报警等级展示报警事件
export function _GetAlarmLevel(carTypeId) {
  return service({
      url: '/protocol-nacos/carAlarm/getAlarmLevel',
      method: 'get',
      params:{
        carTypeId
      }
  })
}
// 报警控制器展示报警事件
export function _GetAlarmController(carTypeId) {
  return service({
      url: '/protocol-nacos/carAlarm/getAlarmControllerNum',
      method: 'get',
      params:{
        carTypeId
      }
  })
}

// 高频次手柄接管统计
export function getFrequentTakeoverData() {
  return service({
      url: '/data-manage/board/getFrequentTakeoverData',
      method: 'get'
  })
}


export function countAlarmItem() {
  return service({
      url: '/protocol-nacos/carAlarm/countAlarmItem',
      method: 'get'
  })
}
