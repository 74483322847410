<template>
  <div>
    <div class="analysis" v-loading="analysisLoading">
      <el-row :gutter="20">
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <div class="item_icon">
              <i class="el-icon-truck"></i>
            </div>
            <div class="item_content">
              <div class="content_title">总接入量</div>
              <div class="content_text">
                <ul class="scroll_ul flex" style="border-bottom: none">
                  <ScrollNum
                    v-for="(num, idx) of vehileTotalCount"
                    :key="idx"
                    as="li"
                    :i="num"
                    :delay="idx + 1"
                  />
                </ul>
                <span>（台）</span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <div class="item_icon">
              <i class="el-icon-guide"></i>
            </div>
            <div class="item_content">
              <div class="content_title">总运营里程</div>
              <div class="content_text">
                <ul style="display: flex; margin: 0; padding: 0">
                  <ScrollNum
                    v-for="(num, idx) of mileTotalCount"
                    :key="idx"
                    as="li"
                    :i="num"
                    :delay="idx + 1"
                  />
                </ul>
                <span>（KM）</span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <div class="item_icon">
              <i class="el-icon-s-flag"></i>
            </div>
            <div class="item_content">
              <div class="content_title">总注册园区</div>
              <div class="content_text">
                <ul style="display: flex; margin: 0; padding: 0">
                  <ScrollNum
                    v-for="(num, idx) of parkTotalCount"
                    :key="idx"
                    as="li"
                    :i="num"
                    :delay="idx + 1"
                  />
                </ul>
                <span>（个）</span>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="analysis">
      <el-row :gutter="20">
        <el-col :span="16">
          <blockTitle :title="'车辆分布概览'"></blockTitle>
          <div class="grid-content flex-d-c">
            <div class="content_top">
              <div id="chart1" style="width: 50%; height: 350px"></div>
              <div id="chart2" style="width: 50%; height: 350px"></div>
            </div>
            <div class="content_bottom">
              <div id="chart3" style="width: 100%; height: 300px"></div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="business_block">
            <blockTitle :title="'车辆运营概览'"></blockTitle>
            <el-select
              v-model="value"
              placeholder="请选择时间范围"
              size="mini"
              @change="changeDayArea"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <div class="business_block">
            <div class="content_item">
              <div class="business_item">
                <div>日平均运营里程</div>
                <div style="font-weight: bold; font-size: 24px">
                  {{
                    businessGenralViewData.dailyAvgMileage
                      ? businessGenralViewData.dailyAvgMileage
                      : 0
                  }}(KM)
                </div>
              </div>
              <div class="business_percent">
                <div
                  :style="
                    'width:' +
                    (businessGenralViewData.autoDriveDailyAvgMileage /
                      businessGenralViewData.dailyAvgMileage) *
                      100 +
                    '%;min-width:26%'
                  "
                >
                  <div class="item_mode" style="background-color: #04bfbe">自动驾驶</div>
                  <div class="item_num">
                    {{
                      businessGenralViewData.autoDriveDailyAvgMileage
                        ? businessGenralViewData.autoDriveDailyAvgMileage
                        : 0
                    }}KM
                  </div>
                </div>
                <div
                    :style="
                    'width:' +
                    (businessGenralViewData.manualDriveDailyAvgMileage /
                      businessGenralViewData.dailyAvgMileage) *
                      100 +
                    '%;min-width:28%'
                  "
                >
                  <div class="item_mode" style="background-color: #80d3f8">人工驾驶</div>
                  <div class="item_num">
                    {{
                      businessGenralViewData.manualDriveDailyAvgMileage
                          ? businessGenralViewData.manualDriveDailyAvgMileage
                          : 0
                    }}KM
                  </div>
                </div>
                <div
                  :style="
                    'width:' +
                    (businessGenralViewData.remoteDriveDailyAvgMileage /
                      businessGenralViewData.dailyAvgMileage) *
                      100 +
                    '%;min-width: 20%'
                  "
                >
                  <div class="item_mode" style="background-color: #7f7f7f">远程</div>
                  <div class="item_num">
                    {{
                      businessGenralViewData.remoteDriveDailyAvgMileage
                        ? businessGenralViewData.remoteDriveDailyAvgMileage
                        : 0
                    }}KM
                  </div>
                </div>
              </div>
            </div>
            <div class="content_item">
              <div class="business_item">
                <div>日平均运营时长</div>
                <div style="font-weight: bold; font-size: 24px">
                  {{
                    businessGenralViewData.dailyAvgDuration
                      ? businessGenralViewData.dailyAvgDuration
                      : 0
                  }}(H)
                </div>
              </div>
              <div class="business_percent">
                <div
                  :style="
                    'width:' +
                    (businessGenralViewData.autoDriveDailyAvgDuration /
                      businessGenralViewData.dailyAvgDuration) *
                      100 +
                    '%;min-width:26%'
                  "
                >
                  <div class="item_mode" style="background-color: #04bfbe">自动驾驶</div>
                  <div class="item_num">
                    {{
                      businessGenralViewData.autoDriveDailyAvgDuration
                        ? businessGenralViewData.autoDriveDailyAvgDuration
                        : 0
                    }}H
                  </div>
                </div>
                <div
                    :style="
                    'width:' +
                    (businessGenralViewData.manualDriveDailyAvgDuration /
                      businessGenralViewData.dailyAvgDuration) *
                      100 +
                    '%;min-width:28%'
                  "
                >
                  <div class="item_mode" style="background-color: #80d3f8">人工驾驶</div>
                  <div class="item_num">
                    {{
                      businessGenralViewData.manualDriveDailyAvgDuration
                          ? businessGenralViewData.manualDriveDailyAvgDuration
                          : 0
                    }}H
                  </div>
                </div>
                <div
                  :style="
                    'width:' +
                    (businessGenralViewData.remoteDriveDailyAvgDuration /
                      businessGenralViewData.dailyAvgDuration) *
                      100 +
                    '%;min-width: 20%'
                  "
                >
                  <div class="item_mode" style="background-color: #7f7f7f">远程</div>
                  <div class="item_num">
                    {{
                      businessGenralViewData.remoteDriveDailyAvgDuration
                        ? businessGenralViewData.remoteDriveDailyAvgDuration
                        : 0
                    }}H
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="business_radio">
            <el-radio-group v-model="radio3" size="small" @change="changeRadio">
              <el-radio-button label="按车型"></el-radio-button>
              <el-radio-button label="按园区"></el-radio-button>
            </el-radio-group>
          </div>

          <div
            id="chart5"
            style="width: 100%; height: 400px; background-color: #fff"
            v-if="chart5Data.xSeries.length"
          ></div>
          <div v-else style="width: 100%; height: 400px; background-color: #fff">
            <el-empty :image="emptyImage" :image-size="100"></el-empty>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="analysis">
      <el-row :gutter="20">
        <el-col :span="16">
          <blockTitle :title="'紧急制动事件概览（近30天）'"></blockTitle>
          <div class="grid-content bg-purple break_block">
            <div class="break_block_item">
              <el-card shadow="always">
                <div>平均每公里制动次数</div>
                <div style="font-size: 30px">
                  {{ breakOverviewData ? breakOverviewData.avgMileBrakeAmount : 0
                  }}<span> 次</span>
                </div>
              </el-card>
              <el-card shadow="always" style="margin-top: 20px">
                <div>平均每小时制动次数</div>
                <div style="font-size: 30px">
                  {{ breakOverviewData ? breakOverviewData.avgHourBrakeAmount : 0
                  }}<span> 次</span>
                </div>
              </el-card>
            </div>
            <div style="height: 300px; width: 70%; display: flex">
              <div id="chart6" style="height: 300px; width: 100%"></div>
              <el-select
                v-model="value1"
                placeholder="请选择"
                size="mini"
                style="padding-right: 20px"
                @change="vehicleTypeChange"
              >
                <el-option
                  v-for="item in vehicleTypes"
                  :key="item.value"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div
            style="
              display: flex;
              justify-content: space-between;
              background-color: #fff;
              align-items: center;
              padding-right: 10px;
            "
          >
            <blockTitle :title="'报警情况分析（近3天）'"></blockTitle>
            <el-button size="mini" @click="$router.push('/carWarnLog')"
              >查看详细数据</el-button
            >
          </div>
          <div class="grid-content bg-purple warning_box">
            <el-table
              :data="tableData"
              v-loading="loading"
              border
              size="mini"
              height="274"
              style="width: 100%"
            >
              <el-table-column prop="vehicleId" label="车辆编号" width="120">
              </el-table-column>
              <el-table-column prop="vehicleType" label="车辆类型" width="100">
              </el-table-column>
              <el-table-column prop="parkName" label="所属园区"> </el-table-column>
              <!-- prop="alarmDesc" -->
              <el-table-column label="报警内容">
                <template slot-scope="scope">
                  <div class="warning_img">
                    <span>{{ scope.row.alarmDesc }}</span>
                    <img
                      v-if="scope.row.alarmLevel == 2"
                      src="@/assets/images/orange-warning.png"
                    />
                    <img
                      v-if="scope.row.alarmLevel == 1"
                      src="@/assets/images/red-warning.png"
                    />
                  </div> </template
                >>
              </el-table-column>
            </el-table>
            <el-pagination
              small
              layout="prev, pager, next"
              :total="total"
              :page-size="searchData.pageSize"
              @current-change="currentChange"
            >
            </el-pagination>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="analysis">
      <el-row :gutter="20">
        <el-col :span="16">
          <div
            style="
              display: flex;
              justify-content: space-between;
              background-color: #fff;
              align-items: center;
              padding-right: 10px;
            "
          >
            <blockTitle title="报警事件概览"></blockTitle>
          </div>

          <div class="grid-content bg-purple warning_box">
            <h4>
              累计报警<span>{{ alarmTotal }}</span
              >（次）
            </h4>
            <el-row style="width: 100%">
              <el-col :span="12" style="text-align: center">
                <el-select
                  v-model="value7"
                  placeholder="请选择"
                  size="mini"
                  @change="chart7Change"
                  style="width: 120px"
                >
                  <el-option
                    v-for="item in vehicleTypes"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <div id="chart7" style="width: 100%; height: 270px"></div>
              </el-col>
              <el-col :span="12" style="text-align: center">
                <el-select
                  style="width: 120px"
                  v-model="value9"
                  placeholder="请选择"
                  size="mini"
                  @change="chart9Change"
                >
                  <el-option
                    v-for="item in vehicleTypes"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <div id="chart9" style="width: 100%; height: 270px"></div>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="8">
          <div
            style="
              display: flex;
              justify-content: space-between;
              background-color: #fff;
              align-items: center;
              padding-right: 10px;
            "
          >
            <blockTitle title="高频次手柄接管统计（近30天）"></blockTitle>
            <el-button size="mini" @click="$router.push('/carBusiness')"
              >查看详细数据</el-button
            >
          </div>
          <div class="grid-content bg-purple warning_box">
            <div class="business_radio" style="margin-top: 14px">
              <el-radio-group v-model="radio4" size="mini" @change="changeRadio4">
                <el-radio-button label="按线路"></el-radio-button>
                <el-radio-button label="按车辆"></el-radio-button>
              </el-radio-group>
            </div>
            <div id="chart8" style="width: 100%; height: 300px"></div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import { _CarTypeOption, _ParkOption } from "../../api/global";
import {
  _FindAnalysisData,
  _boardGeneralView,
  _vehicleDistribution,
  _parkVehicleRate,
  _businessGeneralView,
  _breakOverview,
  _breakDistribution,
  _alarmInfo,
  _berakDetail,
  _businessDistribution,
  _GetAlarmLevel,
  _GetAlarmController,
  getFrequentTakeoverData,
  countAlarmItem,
} from "../../api/dataAnalysis/analysis";
import { _CarType } from "../../api/sass/index";
import blockTitle from "./components/blockTitle.vue";
import ScrollNum from "./components/scrollNum.vue";
import china from "../../until/china1.json";
import { scaleListener } from "../../until/resize";
let mapObjChart = null;
export default {
  data() {
    return {
      emptyImage: require("../../assets/images/empty.png"),
      ParkOption: [],
      CarTypeOption: [],
      analysisLoading: false,
      radio3: "按车型",
      radio4: "按线路",
      chartLoading: false,
      options: [
        {
          value: "7",
          label: "近7天",
        },
        {
          value: "30",
          label: "近1个月",
        },
        {
          value: "90",
          label: "近3个月",
        },
        {
          value: "180",
          label: "近半年",
        },
      ],
      options1: [],
      value1: "全部",
      value: "7",
      type: "1",
      vehicleTypes: [],
      businessGenralViewData: "",
      breakOverviewData: "",
      searchParams: {
        parkId: "",
        carType: "",
        carNumber: "",
        dateRange: [],
      },
      tableData: [],
      loading: false,
      searchData: {
        pageNum: 1,
        pageSize: 6,
      },
      total: 0,
      topGeneralViewData: {
        vehileTotalCount: 0,
        mileTotalCount: 0,
        parkTotalCount: 0,
      },
      chart5Data: {
        xSeries: [],
        ySeries1: [],
        ySeries2: [],
      },
      objChart: null,
      objChart7: null,
      objChart9: null,
      value9: "",
      value7: "",
      alarmTotal: 0,
    };
  },
  components: {
    blockTitle,
    ScrollNum,
  },
  computed: {
    vehileTotalCount() {
      const str = String(this.topGeneralViewData.vehileTotalCount);
      let arr = [];
      for (let i = 0; i < str.length; i++) {
        arr.push(parseInt(str[i]));
      }
      return arr;
    },
    mileTotalCount() {
      const str = String(this.topGeneralViewData.mileTotalCount);
      let arr = [];
      for (let i = 0; i < str.length; i++) {
        arr.push(parseInt(str[i]));
      }
      return arr;
    },
    parkTotalCount() {
      const str = String(this.topGeneralViewData.parkTotalCount);
      let arr = [];
      for (let i = 0; i < str.length; i++) {
        arr.push(parseInt(str[i]));
      }
      return arr;
    },
    percentWidth0(data) {
      if (Number(data) === 0) {
        return "display: none";
      } else {
        return "display: block";
      }
    },
  },
  async mounted() {
    scaleListener();
    this.getCarTypes();
    this.getAllData();
    this.initChart8();
    this.initChart7();
    this.initChart9();
    const { data } = await countAlarmItem();
    this.alarmTotal = data.count || 0;
  },
  methods: {
    chart7Change(res) {
      this.initChart7(res);
    },
    chart9Change(res) {
      this.initChart9(res);
    },
    getCarTypes() {
      _CarType().then((res) => {
        if (res.code === 10000) {
          let vehicleTypes = [
            {
              name: "全部",
              value: "all",
            },
          ];
          res.data.list.map((item) => {
            vehicleTypes.push({
              name: item.typeName,
              value: item.id,
            });
          });
          this.vehicleTypes = vehicleTypes;
        }
      });
    },
    getAllData() {
      this.getTopGeneralView();
      this.getVehicleDistribution();

      this.getParkVehicleRate();

      this.getbusinessGeneralView();
      this.getBusinessDistribution();

      this.getBreakOverview();
      this.getBreakDistribution();

      this.getAlarmInfo();
    },
    getTopGeneralView() {
      _boardGeneralView().then((res) => {
        if (res.code === 0) {
          this.topGeneralViewData.vehileTotalCount = Number(res.data.vehicleAmount);
          this.topGeneralViewData.mileTotalCount = Number(res.data.totalMileage);
          this.topGeneralViewData.parkTotalCount = Number(res.data.totalParks);
        }
      });
    },
    getVehicleDistribution() {
      _vehicleDistribution().then((res) => {
        if (res.code === 0) {
          let cityMapList = [];
          let cityTop8 = {
            cityList: [],
            countList: [],
          };
          res.list.forEach((item, index) => {
            if (item.city && item.city !== "[]") {
              cityMapList.push({
                name: item.city,
                value: [
                  Number(item.longitude),
                  Number(item.latitude),
                  item.vehicleAmount,
                ],
              });
            }

            if (index < 8 && item.city && item.city !== "[]") {
              cityTop8.cityList.push(item.city);
              cityTop8.countList.push(item.vehicleAmount);
            }
          });
          this.initChart1(cityMapList);
          this.initChart2(cityTop8);
        }
      });
    },
    getParkVehicleRate() {
      _parkVehicleRate().then((res) => {
        if (res.code === 0) {
          let obj = {
            pieChartData: [],
            lengendData: [],
          };
          let nullCount = 0;
          res.list.forEach((item, index) => {
            if (item.parkName) {
              obj.pieChartData.push({
                value: item.vehicleAmount,
                name: item.parkName,
              });
              obj.lengendData.push(item.parkName);
            } else {
              nullCount += item.vehicleAmount;
            }
          });
          if (nullCount !== 0) {
            obj.pieChartData.push({
              value: nullCount,
              name: "未分配",
            });
            obj.lengendData.push("未分配");
          }
          this.initChart3(obj);
        }
      });
    },
    getbusinessGeneralView() {
      _businessGeneralView({ days: this.value }).then((res) => {
        this.businessGenralViewData = res.data ? res.data : {};
      });
    },
    changeDayArea(e) {
      this.value = e;
      this.getbusinessGeneralView();
      this.getBusinessDistribution();
    },
    getBusinessDistribution() {
      // 查询车辆运营概览柱状图
      _businessDistribution({
        type: this.radio3 === "按车型" ? "1" : "2",
        days: this.value,
      }).then((res) => {
        if (res.code === 0) {
          let chart5Data = {
            xSeries: [],
            ySeries1: [],
            ySeries2: [],
          };
          res.list.length &&
            res.list.forEach((item) => {
              if (item.dimensionName) {
                chart5Data.xSeries.push(item.dimensionName);
                chart5Data.ySeries1.push(item.totalMileage);
                chart5Data.ySeries2.push(item.dailyAvgDuration);
              }
            });
          this.chart5Data = chart5Data;
          this.$nextTick(() => {
            res.list.length && this.initChart5(chart5Data);
          });
        }
      });
    },
    changeRadio() {
      this.getBusinessDistribution();
    },
    changeRadio4() {
      this.initChart8();
    },
    getBreakOverview() {
      _breakOverview().then((res) => {
        if (res.code === 0) {
          this.breakOverviewData = res.data;
        }
      });
    },
    getBreakDistribution() {
      _breakDistribution({
        vehicleType: this.value1 === "全部" ? "" : this.value1,
      }).then((res) => {
        if (res.code === 0) {
          let data = [];
          res.list.forEach((item) => {
            data.push({
              name: item.brakeType,
              value: item.brakeAmount,
            });
          });
          this.initChart6(data);
        }
      });
    },
    vehicleTypeChange(e) {
      this.getBreakDistribution();
    },
    getAlarmInfo() {
      this.loading = true;
      _alarmInfo(this.searchData).then((res) => {
        if (res.code === 0) {
          this.tableData = res.list;
          this.total = res.total;
        }
        this.loading = false;
      });
    },
    initChart1(data) {
      mapObjChart = echarts.init(document.getElementById("chart1"));
      echarts.registerMap("china", china);

      let option = {
        backgroundColor: "transparent",
        tooltip: {
          trigger: "item",
        },

        geo: {
          show: true,
          center: [104.114129, 35.550339],
          map: "china",
          label: {
            normal: {
              show: false,
            },
            emphasis: {
              show: false,
            },
          },
          zLevel: 110,
          zoom: 1.7,
          roam: true,
          itemStyle: {
            areaColor: {
              type: "radial",
              x: 0.5,
              y: 0.5,
              r: 3,
              colorStops: [
                {
                  offset: 0,
                  color: "rgba(223, 231, 242, 1)", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "rgba(2, 99, 206, 1)", // 100% 处的颜色
                },
              ],
            },
            emphasis: {
              areaColor: {
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(223, 231, 242, 1)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(2, 99, 206, 1)", // 100% 处的颜色
                  },
                ],
              },
              borderColor: "rgba(104, 152, 190, 1)", // 边框样式
              shadowColor: "rgba(128, 217, 248, 1)", // 阴影颜色
            },
          },
        },

        series: [
          {
            name: "车辆数量",
            type: "effectScatter",
            coordinateSystem: "geo",
            zlevel: 111, // 增加zleve属性可降低动画导致的CPU占用率过高问题
            data: data ? data : [],
            showEffectOn: "render",
            rippleEffect: {
              //  涟漪特效定制
              period: 4, //动画时间，值越小速度越快
              brushType: "stroke", //波纹绘制方式 stroke, fill
              scale: 2, //波纹圆环最大限制，值越大波纹越大 4
            },
            encode: {
              value: 2,
            },
            symbolSize: function (val) {
              return val[2] + 5;
            },
            label: {
              formatter: "{b}",
              position: "right",
            },
            itemStyle: {
              color: "#ddb926",
            },
            emphasis: {
              label: {
                show: true,
              },
            },
          },
        ],
      };
      mapObjChart.setOption(option);
    },
    initChart2(data) {
      let objChart = echarts.init(document.getElementById("chart2"));
      objChart.setOption({
        title: {
          text: "城市分布Top（8）",
          left: "center",
          textStyle: {
            fontSize: 14,
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "8%",
          bottom: "3%",
          top: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          show: false,
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          show: true,
          type: "category",
          boundaryGap: true,
          axisLine: {
            show: false,
          },
          axisTick: {
            //y轴刻度线
            show: false,
          },
          data: data ? data.cityList.reverse() : [],
        },
        series: [
          {
            type: "bar",
            label: {
              show: true,
              position: "inside",
              color: "#fff",
            },
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  1,
                  0,
                  [
                    {
                      offset: 0,
                      color: "#7ea8ee", // 0% 处的颜色
                    },
                    {
                      offset: 0.6,
                      color: "#08a4fa", // 60% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#5d87f4", // 100% 处的颜色
                    },
                  ],
                  false
                ),
              },
            },
            data: data ? data.countList.reverse() : [],
          },
        ],
      });
    },
    initChart3(data) {
      let objChart = echarts.init(document.getElementById("chart3"));
      objChart.setOption({
        title: {
          text: "各园区车辆占比",
          show: true,
          left: "center",
          textStyle: {
            fontSize: 14,
            lineHeight: 56,
          },
        },
        legend: {
          type: "",
          orient: "horizontal",
          x: "right",
          y: "center",
          right: 20,
          left: "70%",
          data: data ? data.lengendData : [],
        },
        series: [
          {
            name: "各园区车辆占比",
            type: "pie",
            radius: "70%",
            center: ["30%", "60%"],
            roseType: "radius",
            itemStyle: {
              borderRadius: 10,
            },
            label: {
              show: true,
              normal: {
                formatter: "{b}:{c}台({d}%)",
                color: "rgba(1, 1, 1, 0.5)",
              },
            },
            animationType: "scale",
            animationEasing: "elasticOut",
            animationDelay: function () {
              return Math.random() * 1;
            },
            emphasis: {
              label: {
                show: true,
              },
            },
            data: data ? data.pieChartData : [],
          },
        ],
      });
    },
    initChart5(data) {
      let objChart = echarts.init(document.getElementById("chart5"));
      objChart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        grid: {
          top: "10%",
        },
        xAxis: [
          {
            type: "category",
            data: data ? data.xSeries : [],
            axisPointer: {
              type: "shadow",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "运行里程",
          },
          {
            type: "value",
            name: "运营时长",
            axisLabel: {
              formatter: "{value} H",
            },
          },
        ],
        series: [
          {
            name: "运营里程",
            type: "bar",
            data: data ? data.ySeries1 : [],
          },
          {
            name: "运营时长",
            type: "line",
            yAxisIndex: 1,
            data: data ? data.ySeries2 : [],
          },
        ],
      });
    },
    initChart6(data) {
      let objChart = echarts.init(document.getElementById("chart6"));
      objChart.setOption({
        tooltip: {
          trigger: "item",
        },
        title: {
          text: "紧急制动行为分布",
          left: "center",
        },
        series: [
          {
            type: "pie",
            radius: ["35%", "60%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 2,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              color: "rgba(1, 1, 1, 0.3)",
            },
            data: data ? data : [],
            emphasis: {
              itemStyle: {
                label: {
                  show: true,
                },
                shadowBlur: 20,
                shadowOffsetX: 0,
                shadowColor: "rgba(1, 1, 1, 0.5)",
              },
            },
          },
        ],
      });
    },
    async initChart8() {
      const res = await getFrequentTakeoverData();
      if (!this.objChart) {
        this.objChart = echarts.init(document.getElementById("chart8"));
      }
      const color = ["#40b0e2", "#69e5c1", "#626b90", "#a0a7e5", "#c3eaad", "#95dde7"];
      let tep = [];
      let data = [];
      let legend = [];
      if (this.radio4 == "按线路") {
        tep = res.data.lineTakeoverData.map((item) => item.times) || [];
        legend = res.data.lineTakeoverData.map((item) => item.keyName) || [];
      } else {
        tep = res.data.vidTakeoverData.map((item) => item.times) || [];
        legend = res.data.vidTakeoverData.map((item) => item.keyName) || [];
      }

      tep.map((item, index) => {
        data[index] = {
          value: item,
          itemStyle: {
            color: color[index],
          },
        };
      });
      this.objChart.setOption({
        tooltip: {
          trigger: "item",
        },
        grid: {
          top: "0",
          left: "3%",
          right: "8%",
          bottom: "1%",
          containLabel: true,
        },
        xAxis: [
          {
            show: false,
            type: "value",
          },
        ],
        yAxis: [
          {
            type: "category",
            axisTick: {
              show: false,
            },
            data: legend,
          },
        ],
        series: [
          {
            type: "bar",
            stack: "Total",
            label: {
              show: true,
              position: "right",
            },
            emphasis: {
              focus: "series",
            },
            data,
          },
        ],
      });
      window.addEventListener("resize", () => {
        this.objChart.resize();
      });
    },
    async initChart7(id) {
      if (id == "all") id = null;
      const res = await _GetAlarmLevel(id);
      const list = res.data;

      if (!this.objChart7) {
        this.objChart7 = echarts.init(document.getElementById("chart7"));
      }
      const color = ["#40b0e2", "#69e5c1", "#626b90", "#a0a7e5", "#c3eaad", "#95dde7"];
      let data = [];
      list.map((item) => {
        const { levelNum, warningLevel } = item;
        let name = "";
        switch (warningLevel) {
          case "1":
            name = "一级报警";
            break;
          case "2":
            name = "二级报警";
            break;
          case "3":
            name = "三级报警";
            break;
          default:
            name = "报警";
        }
        data.push({
          name,
          value: levelNum,
        });
      });

      this.objChart7.setOption({
        tooltip: {
          trigger: "item",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          orient: "vertical",
          left: "right",
          bottom: 30,
        },
        grid: {
          top: "top",
        },
        series: [
          {
            type: "pie",
            stack: "Total",
            radius: "55%",
            emphasis: {
              focus: "series",
            },
            data,
            labelLine: {
              show: true,
              length: 5,
              length2: 5,
            },
          },
        ],
      });
      window.addEventListener("resize", () => {
        this.objChart7.resize();
      });
    },
    async initChart9(id) {
      if (!this.objChart9) {
        this.objChart9 = echarts.init(document.getElementById("chart9"));
      }
      if (id == "all") id = null;
      const res = await _GetAlarmController(id);
      const list = res.data;
      // _GetAlarmController()
      let data = [];
      list.map((item) => {
        const { vehicleWarningController, controllerNum } = item;
        data.push({
          name: vehicleWarningController,
          value: controllerNum,
        });
      });
      this.objChart9.setOption({
        tooltip: {},
        color: ["#40b0e2", "#69e5c1", "#626b90", "#a0a7e5", "#c3eaad", "#95dde7"],
        legend: {
          orient: "vertical",
          left: "right",
          bottom: 30,
        },

        series: [
          {
            type: "pie",
            radius: "55%",
            data,
            labelLine: {
              show: true,
              length: 5,
              length2: 5,
            },
          },
        ],
      });
      window.addEventListener("resize", () => {
        this.objChart9.resize();
      });
    },
    randomColor() {
      const r = Math.floor(Math.random() * 256); //随机生成256以内r值
      const g = Math.floor(Math.random() * 256); //随机生成256以内g值
      const b = Math.floor(Math.random() * 256); //随机生成256以内b值
      const alpha = Math.random(); //随机生成1以内a值
      return `rgb(${r},${g},${b},${alpha})`; //返回rgba(r,g,b,a)格式颜色
    },
    checkAlarmDetail() {
      this.$router.push("/checkAlarmDetail");
    },
    //获取园区选项
    async GetParkOption() {
      let res = await _ParkOption();

      if (res.success) {
        this.ParkOption = res.data;
      }
    },

    //获取车辆类型选项
    async GetCarTypeOption() {
      let res = await _CarTypeOption();

      if (res.success) {
        this.CarTypeOption = res.data;
      }
    },

    //获取页面数据
    async GetPageData() {
      this.analysisLoading = true;
      this.chartLoading = true;

      let jsonParams = {
        parkId: this.searchParams.parkId,
        carType: this.searchParams.carType,
        carNumber: this.searchParams.carNumber,
        beginDate: "",
        endDate: "",
      };

      if (this.searchParams.dateRange === null) {
        this.searchParams.dateRange = [];
      }

      if (this.searchParams.dateRange.length === 2) {
        jsonParams.beginDate = this.searchParams.dateRange[0];
        jsonParams.endDate = this.searchParams.dateRange[1];
      }

      let res = await _FindAnalysisData(jsonParams);

      if (res.success) {
        //region 统计数据
        this.statisticalAnalysisData.selectedCarNum = res.data.parkDataComm
          ? res.data.parkDataComm
          : 0;
        this.statisticalAnalysisData.avgDayRange = res.data.avgDayRange
          ? res.data.avgDayRange
          : 0;
        this.statisticalAnalysisData.avgDayOnlineRate = res.data.avgDayOnlineRate
          ? res.data.avgDayOnlineRate
          : 0;
        this.statisticalAnalysisData.avgDayConsumeSoc = res.data.avgDayConsumeSoc
          ? res.data.avgDayConsumeSoc
          : 0;
        this.statisticalAnalysisData.avgDayErrorNum = res.data.avgDayErrorNum
          ? res.data.avgDayErrorNum
          : 0;
        //endregion 统计数据

        //region 图表数据
        let nChargeSoc = res.data.chart.carChargeDataAnalyse.soc;

        if (nChargeSoc !== "") {
          nChargeSoc = parseInt(Number(nChargeSoc));
        }

        this.chartData.dayOnlineRate = res.data.chart.dayOnlineRate;
        this.chartData.dayAvgRuntime = res.data.chart.dayAvgRuntime;
        this.chartData.dayAvgMileage = res.data.chart.dayAvgMileage;
        this.chartData.dayAvgCarSpeed = res.data.chart.dayAvgCarSpeed;
        this.chartData.dayOrderNum = res.data.chart.dayOrderNum;
        this.chartData.daySOC = res.data.chart.daySoc;
        this.chartData.carChargeDataAnalyse.soc = nChargeSoc > 100 ? 100 : nChargeSoc;
        this.chartData.carChargeDataAnalyse.dayChargeDuration =
          res.data.chart.carChargeDataAnalyse.dayChargeDuration;
        this.chartData.carErrorDataAnalyse.errorTypeRate =
          res.data.chart.carErrorDataAnalyse.errorTypeRate;
        this.chartData.carErrorDataAnalyse.dayAvgErrorNum =
          res.data.chart.carErrorDataAnalyse.dayAvgErrorNum;
        this.chartData.carErrorDataAnalyse.dayAvgKmErrorNum =
          res.data.chart.carErrorDataAnalyse.dayAvgKmErrorNum;
        this.chartData.carErrorDataAnalyse.dayAvgBrakeNum =
          res.data.chart.carErrorDataAnalyse.dayAvgBrakeNum;
        //endregion 统计数据

        this.analysisLoading = false;
        this.chartLoading = false;
      }
    },

    async SearchPageData() {
      await this.GetPageData();
      this.InitChart();
    },

    //region 图表
    //初始化图表
    InitChart() {
      this.SetChartDayOnlineRate();
      this.SetChartDayAvgRuntime();
      this.SetChartDayAvgMileage();
      this.SetChartDayAvgCarSpeed();
      this.SetChartDayOrderNum();
      this.SetChartDaySOC();
      this.SetChartDayChargeDuration();
      this.SetChartErrorTypeRate();
      this.SetChartDayAvgKmErrorNum();
      this.SetChartDayAvgErrorNum();
      this.SetChartDayAvgBrakeNum();
    },

    //设置每日上线率图表
    SetChartDayOnlineRate() {
      if (this.chartData.dayOnlineRate.length <= 0) return false;

      let arrDate = [];
      let arrValue = [];

      for (const item of this.chartData.dayOnlineRate) {
        arrDate.push(item.date);
        arrValue.push(item.val);
      }

      let objChart = echarts.init(document.getElementById("ChartDayOnlineRate"));
      this.DrawChart(objChart, "#4f84b4", "每日上线率（%）", "", arrDate, arrValue);
    },

    //每日平均运行时长
    SetChartDayAvgRuntime() {
      if (this.chartData.dayAvgRuntime.length <= 0) return false;

      let arrDate = [];
      let arrValue = [];

      for (const item of this.chartData.dayAvgRuntime) {
        arrDate.push(item.date);
        arrValue.push(item.val);
      }

      let objChart = echarts.init(document.getElementById("ChartDayAvgRuntime"));
      this.DrawChart(
        objChart,
        "#5974c7",
        "每日平均运行时长（小时）",
        "",
        arrDate,
        arrValue
      );
    },

    //每日平均行驶里程
    SetChartDayAvgMileage() {
      if (this.chartData.dayAvgMileage.length <= 0) return false;

      let arrDate = [];
      let arrValue = [];

      for (const item of this.chartData.dayAvgMileage) {
        arrDate.push(item.date);
        arrValue.push(item.val);
      }

      let objChart = echarts.init(document.getElementById("ChartDayAvgMileage"));
      this.DrawChart(
        objChart,
        "#5e4e9e",
        "每日平均行驶里程（km）",
        "",
        arrDate,
        arrValue
      );
    },

    //每日平均车速
    SetChartDayAvgCarSpeed() {
      if (this.chartData.dayAvgCarSpeed.length <= 0) return false;

      let arrDate = [];
      let arrValue = [];
      let nTotalValue = 0;

      for (const item of this.chartData.dayAvgCarSpeed) {
        arrDate.push(item.date);
        arrValue.push(item.val);
      }

      let objChart = echarts.init(document.getElementById("ChartDayAvgCarSpeed"));
      this.DrawChart(objChart, "#c16cb5", "每日平均车速（km/h）", "", arrDate, arrValue);
    },

    //每日订单数
    SetChartDayOrderNum() {
      if (this.chartData.dayOrderNum.length <= 0) return false;

      let arrDate = [];
      let arrValue = [];

      for (const item of this.chartData.dayOrderNum) {
        arrDate.push(item.date);
        arrValue.push(item.val);
      }

      let objChart = echarts.init(document.getElementById("ChartDayOrderNum"));
      this.DrawChart(objChart, "#c2516e", "每日订单数（次）", "", arrDate, arrValue);
    },

    //每日平均消耗SOC
    SetChartDaySOC() {
      if (this.chartData.daySOC.length <= 0) return false;

      let arrDate = [];
      let arrValue = [];

      for (const item of this.chartData.daySOC) {
        arrDate.push(item.date);
        arrValue.push(item.val);
      }

      let objChart = echarts.init(document.getElementById("ChartDaySOC"));
      this.DrawChart(objChart, "#d15d5d", "每日平均消耗SOC（%）", "", arrDate, arrValue);
    },

    //每日充电时长
    SetChartDayChargeDuration() {
      if (this.chartData.carChargeDataAnalyse.dayChargeDuration.length <= 0) return false;

      let arrDate = [];
      let arrValue = [];

      for (const item of this.chartData.carChargeDataAnalyse.dayChargeDuration) {
        arrDate.push(item.date);
        arrValue.push(item.val);
      }

      let objChart = echarts.init(document.getElementById("ChartDayChargeDuration"));
      this.DrawChart(objChart, "#4f84b4", "每日充电时长（小时）", "", arrDate, arrValue);
    },

    //各故障类型占比
    SetChartErrorTypeRate() {
      if (this.chartData.carErrorDataAnalyse.errorTypeRate.length <= 0) return false;

      let arrChartData = [];

      for (const item of this.chartData.carErrorDataAnalyse.errorTypeRate) {
        arrChartData.push({
          name: item.date,
          value: item.val,
        });
      }

      let objChart = echarts.init(document.getElementById("ChartErrorTypeRate"));
      let option = {
        title: {
          text: "各故障类型占比（次数）",
          itemGap: 20,
          left: 20,
          top: 20,
          textStyle: {
            color: "#666",
            fontSize: 16,
            fontFamily:
              "Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif",
          },
          subtextStyle: {
            color: "#333",
            fontSize: 12,
          },
        },
        legend: {
          top: "bottom",
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            radius: [20, 100],
            center: ["50%", "48%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 8,
            },
            data: arrChartData,
          },
        ],
      };

      objChart.setOption(option);
    },

    //每日平均每公里故障次数
    SetChartDayAvgKmErrorNum() {
      if (this.chartData.carErrorDataAnalyse.dayAvgKmErrorNum.length <= 0) return false;

      let arrDate = [];
      let arrValue = [];

      for (const item of this.chartData.carErrorDataAnalyse.dayAvgKmErrorNum) {
        arrDate.push(item.date);
        arrValue.push(item.val);
      }

      let objChart = echarts.init(document.getElementById("ChartDayAvgKmErrorNum"));
      this.DrawChart(
        objChart,
        "#5974c7",
        "每日平均每公里故障次数",
        "",
        arrDate,
        arrValue
      );
    },

    //每日平均故障次数
    SetChartDayAvgErrorNum() {
      if (this.chartData.carErrorDataAnalyse.dayAvgErrorNum.length <= 0) return false;

      let arrDate = [];
      let arrValue = [];

      for (const item of this.chartData.carErrorDataAnalyse.dayAvgErrorNum) {
        arrDate.push(item.date);
        arrValue.push(item.val);
      }

      let objChart = echarts.init(document.getElementById("ChartDayAvgErrorNum"));
      this.DrawChart(objChart, "#5e4e9e", "每日平均故障次数", "", arrDate, arrValue);
    },

    //每日平均故障次数
    SetChartDayAvgBrakeNum() {
      if (this.chartData.carErrorDataAnalyse.dayAvgBrakeNum.length <= 0) return false;

      let arrDate = [];
      let arrValue = [];

      for (const item of this.chartData.carErrorDataAnalyse.dayAvgBrakeNum) {
        arrDate.push(item.date);
        arrValue.push(item.val);
      }

      let objChart = echarts.init(document.getElementById("ChartDayAvgBrakeNum"));
      this.DrawChart(objChart, "#c2516e", "每日平均制动次数", "", arrDate, arrValue);
    },

    //绘制图表
    DrawChart(chart, color, text, subtext, dateList, valList) {
      chart.setOption({
        title: {
          text,
          subtext,
          itemGap: 20,
          left: 20,
          top: 20,
          textStyle: {
            color: "#666",
            fontSize: 16,
            fontFamily:
              "Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif",
          },
          subtextStyle: {
            color: "#333",
            fontSize: 12,
          },
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: dateList, //x轴：日期
          axisLabel: {
            interval: 0, //X轴信息全部展示
            textStyle: {
              align: "center",
            },
          },
        },
        yAxis: {
          type: "value",
        },
        grid: {
          top: "25%", // 等价于 y: '16%'
          left: "5%",
          right: "8%",
          bottom: "0%",
          containLabel: true,
        },
        series: [
          {
            data: valList, //y轴：值
            type: "line",
            smooth: true,
            symbol: "none",
            stack: "Total",
            itemStyle: {
              normal: {
                lineStyle: {
                  color,
                },
              },
            },
          },
        ],
      });
    },
    //endregion 图表

    // warning分页
    currentChange(val) {
      this.searchData.pageNum = val;
      this.getAlarmInfo();
    },
  },
  beforeDestroy() {
    mapObjChart.clear();
  },
};
</script>

<style scoped lang="less">
.empty-title {
  padding-left: 30px;
  padding-top: 10px;
  font-weight: bold;
  color: #666;
  font-size: 16px;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}
.el-row {
  // padding: 20px 0 0 0;
  margin: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 20px 0;
  .item_icon {
    width: 80px;
    height: 80px;
    border-radius: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1683d9;
    i {
      font-size: 50px;
      color: #fff;
    }
    margin-right: 10px;
  }
  .item_content {
    padding: 30px 0;
  }
  .content_title {
    color: #909399;
  }
  .content_text {
    font-size: 32px;
    padding-top: 10px;
    display: flex;
    align-items: end;
    span {
      font-size: 16px;
    }
  }
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.analysis {
  // background-color: #fff;
  margin-bottom: 20px;

  .analyAfter,
  .analyAfterLast {
    // text-align: center;
    // position: relative;
    // top: 20px;
    // height: 60px;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-around;

    // div:first-child {
    // 	font-size: 14px;
    // 	color: #666666;
    // }

    // div:last-child {
    // 	font-size: 20px;
    // 	color: #333333;
    // 	font-weight: 600;
    // }
  }

  .analyAfter:before {
    content: "";
    position: absolute;
    width: 1px;
    height: 35px;
    right: 0px;
    // background-color: #d4d7d9;
  }
}
.top_item {
  background-color: #fff;
}

.circleBox {
  position: relative;
  top: 60px;
  display: flex;
  justify-content: center;
}

.el-progress {
  //   top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
}

.el-progress__text {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  margin-left: 0px;
}

.el-progress__text > p:first-child {
  font-size: 16px;
  font-weight: bold;
}

.el-progress__text > p:last-child {
  font-size: 12px;
  margin-top: 8px;
}

.lineChart {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .lineChartdv {
    background-color: #fff;
    height: 330px;
    width: 49%;
    margin-bottom: 20px;
  }

  .lineChartdv1 {
    background-color: #fff;
    height: 330px;
    width: 50%;
    margin-bottom: 20px;
    position: relative;

    .goDetail {
      font-size: 12px;
      color: #1683d9;
      position: absolute;
      right: 50px;
      top: 60px;
      cursor: pointer;
      z-index: 1;
    }

    .chartWord {
      font-size: 15px;
      font-weight: 600;
      color: #666666;
      transform: translate(20px, 20px);
    }
  }
}
.flex-d-c {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.content_bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 20px;
  border-top: 1px solid #e3e3e3;
  width: 100%;
}
.content_item {
  background-color: #ddd;
  width: 49%;
  text-align: center;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
#chart4 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.pie_chart_item {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .tags {
    color: #fff;
    margin-bottom: 10px;
  }
}
.item_num {
  padding: 10px 0;
  font-size: 12px;
  color: #555;
}
.flex {
  display: flex;
}
.scroll_ul {
  padding: 0;
  margin: 0;
}
.business_block {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  align-items: center;
  padding: 0 10px;
}
.business_item {
  background-color: #fff;
  margin: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.business_percent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
}
.business_radio {
  height: 70px;
  display: flex;
  align-items: end;
  background-color: #fff;
  padding-left: 10px;
}
.break_block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.break_block_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  text-align: center;
}
.warning_box {
  display: flex;
  flex-direction: column;
  height: 300px;
  .warning_img {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 16px;
      height: 16px;
      margin-left: 5px;
    }
  }
}
</style>
