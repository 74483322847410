const scaleListener = () => {
    window.addEventListener('resize', resize)
 
  }
  const resize = () => {
    // 与原来 1080 的比值
    let scale = window.innerHeight / 1080
    document.documentElement.style.fontSize = `${14 * scale}px` 
   
  }
  export {
    scaleListener
  }
  