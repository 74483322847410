<template>
    <div class="container">
        <div class="block_tag"></div>
        <div class="block_text">{{title ? title : '标题'}}</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    props: {
        title: ''
    }
}
</script>

<style scoped>
.container {
    display: flex;
    padding: 10px 0;
    background-color: #fff;
    align-items: center;
}
.block_tag {
    height: 24px;
    width: 8px;
    background-color: #1683d9;
    margin: 0 5px 0 10px;
}
.block_text {
    font-weight: bold;
    font-size: 16px;
}
</style>