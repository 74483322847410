
import service from '../../until/api'

// 车辆注册管理---分页查询
export function _CarOperationType(data) {
    return service({
        url: '/vehicle-auth/carOperationType/findAllCarOperationType',
        method: 'GET',
        params: data
    })
}

export function _CarType(data) {
    return service({
        url: '/vehicle-auth/carType/findAll',
        method: 'GET',
        params: data
    })
}

export function _AddCarType(data) {
    return service({
        url: '/vehicle-auth/carType/addCarType',
        method: 'POST',
        params: data
    })
}
export function _EdtCarType(data) {
    return service({
        url: '/vehicle-auth/carType/updateCarType',
        method: 'POST',
        data
    })
}


//删除车辆类型
export function _DeleteCarType(data) {
    return service({
        url: '/vehicle-auth/carType/deleteCarType',
        method: 'GET',
        params: data
    })
}


//车辆类型管理---分页查询
export function _FindAllPage(data) {
    return service({
        url: '/vehicle-auth/carType/findAllPage',
        method: 'GET',
        params: data
    })
}

//APP管理
export function _AppuserMng(data) {
    return service({
        url: '/user-nacos/appUser/findAllPage',
        method: 'GET',
        params: data
    })
}

export function _AppuserMngStatus(data) {
    return service({
        url: '/user-nacos/appUser/updateAppUserAuth',
        method: 'GET',
        params: data
    })
}


export function _AppuserMngPwd(data) {
    return service({
        url: '/user-nacos/appUser/updateAppUserPassword',
        method: 'GET',
        params: data
    })
}

export function _AddParentCarType(data) {
    return service({
        url: '/vehicle-auth/carType/addCarRootType',
        method: 'post',
        params: data
    })
}